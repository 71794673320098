<!--
 * @Description: 架构信息
 * @Author: luocheng
 * @Date: 2021-11-05 16:02:30
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-07-31 16:40:20
-->
<template>
  <div class="archi-info select-archi-node 22" v-if="archiInfo">
    <h4 class="archi-title select-archi-node">{{ archiInfo.name || archiInfo.short_name || '' }}</h4>
		<!-- 附加公司名称 -->
		<p class="full-name select-archi-node">{{ archiInfo.parent_name || archiInfo.company_name || '' }}</p>
		<section class="action-item select-archi-node" @click="onFuture">
			<i class="iconfont icon-pre iconpingtaishuju"></i>
			<p class="label">{{ archiInfo.id.includes('org_companys') ? '企业' : '项目' }}看板<span style="color:#8d8d8d;font-size:12px">(开发中)</span></p>
			<i class="iconfont icon-go iconxiangyoujiantou2"></i>
		</section>
		<section class="action-item screen" @click="onFuture">
			<i class="iconfont icon-screen icondashboard"></i>
			<p class="label">可视化大屏<span style="color:#8d8d8d;font-size:12px">(开发中)</span></p>
			<i class="iconfont icon-go iconxiangyoujiantou2"></i>
		</section>
		<a
		v-if="targetArchi && targetArchi.id !== archiInfo.id"
			href="javascript:;"
			class="entry-btn"
			@click="onToggleArchi"
		>选择{{ archiInfo.id.includes('org_companys') ? '企业' : '项目' }}</a>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { judgingArchiAPermission } from '@/utils/tools';

export default {
  name: 'ArchiInfo',
	props: {
		reload: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			childrenUUID: '72da4bd8-c508-48c9-8e75-5ed59e6b09d2',
			urlUUID: '7c5166f1-84aa-4639-83f6-7a2a07a518d9',
		}
	},
	computed: {
		...mapState([
			'cacheArchi',
			'targetArchi',
			'globalConfig',
			'systemConfig'
		]),
		// 架构信息
		archiInfo() {
			return this.cacheArchi || this.targetArchi;
		}
	},
	methods: {
		/**
		 * @desc: 切换企业
		 */
		onToggleArchi() {
			this.$loading();
			dataInterface({
				__method_name__: 'globalFunctionCall',
				typeName: 'LoginFuncClass',
				type: 'behavior',
				funcName: 'ToggleArchBehavior',
				payload: {
          __external_company_type:localStorage.getItem('userType'),
					archi_id: this.archiInfo.id,
					archi_table:
						this.archiInfo.id.indexOf('companys') > -1 ? 'org_companys' : 'org_projects'
				}
			}).then((res) => {
				if (!res) {
					return false;
				}
				const targetArchi = {
					...res.data.data,
					archiId: res.data.data.archi_id,
					archiType: this.archiInfo.id.indexOf('companys') > -1 ? 'company' : 'project',
					id: res.data.data.data_id,
					modelApproval:['https://bim.xwjgpt.com/'].includes(process.env.VUE_APP_V3_HOST)? true:false,
					company_id: res.data.data.data.company_id || ''
				}
				console.log('setTargetArchi---10101010')
				this.$store.commit('setTargetArchi', {
					targetArchi,
					a: 11
				});
				window.localStorage.setItem('currentProjectInfo', JSON.stringify(targetArchi))
				localStorage.setItem("userRoles", res.data.data.permissions || '');
				if (res?.data?.data?.userInfo) {
					const userInfo = res?.data?.data?.userInfo || {};
					let roleNames = [];
					if (Array.isArray(userInfo?.roles) && userInfo?.roles.length) {
						userInfo?.roles.forEach(ele => {
							if (ele?.name) {
								roleNames.push(ele.name);
							}
						});
					}
					localStorage.setItem('userInfo', JSON.stringify({
						...userInfo,
						roleName: roleNames.join()
					}));
				}
				// 刷新数据
				sessionStorage.removeItem('navHistoryList');
				this.$store.commit('setNavHistory', []);
				// 跳转到首页
				// 首页路由
				console.log(this.$route.path , '---this.$route.path -')
				this.handleToggleArchi(targetArchi);
				console.log(this.reload, '-----reload-----')
				if (this.reload) {
					// this.$loading();
					setTimeout(() => {
						window.location.reload();
					}, 300);
				}
				eventBus.$emit('closeSelectArchi');
				this.$loading().close();
			}).catch((err) => {
				console.log(err);
				this.$loading().close();
			});
		},
		/**
		 * @desc: 切换组织架构后刷新当前的应用或返回第二页
		 * @param {Object} targetArchi 当前选择的架构
		 */
		handleToggleArchi(targetArchi) {
			if (this.$route.path === '/second-screen') return;
			// 鉴定当前应用是否具有权限
			let currentApp = sessionStorage.getItem('currentApp');
			if (!currentApp) {
				// 位于第二屏
				return true;
			}
			currentApp = JSON.parse(currentApp);
			const { archi_limit = [], permissions = [] } = currentApp
			if (!judgingArchiAPermission(archi_limit, permissions)) {
				// 无权限的情况下跳转首页
				this.$message.warning(`【${targetArchi.short_name}】架构下,无【${currentApp.name}】权限!`);
				const { need_second = 0 } = this.systemConfig;
				if (need_second) {
					// 跳转第二页
					this.$router.replace({
						name: 'SecondScreen'
					});
				}
				return false;
			}
			// 有权限跳转到首页
			const indexObj = this.getIndexPath(currentApp?.app_orign_data || []);
			if (indexObj) {
				this.$router.push({
					path: indexObj.menu_path,
					query: {
						pageUUID: indexObj.menu_type === 0 ? indexObj.page_uuid : ''
					}
				});
				return true;
			}
			return false;
		},
		/**
		 * @desc: 获取应用首页(feature)
		 * @param {Array} list route list
		 */
		getIndexPath(list) {
			for (let i = 0; i < list.length; i++) {
				const { is_index, is_hide, children_list, menu_type } = list[i];
				if (is_index && !is_hide && (+menu_type === 0 || +menu_type === 4)) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getIndexPath(children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 获取路由对象
		 */
		getPathObj(path) {
			let result = null;
			for (let i = 0; i < this.globalConfig.length; i++) {
				if (this.globalConfig[i][this.urlUUID] === path) {
					result = this.globalConfig[i];
					break;
				}
				if (this.globalConfig[i][this.childrenUUID]) {
					const children = this.globalConfig[i][this.childrenUUID];
					for (let j = 0; j < children.length; j++) {
						if (children[j][this.urlUUID] === path) {
							result = children[j];
							break;
						}
						if (children[j][this.childrenUUID]) {
							const grandSons = children[j][this.childrenUUID];
							for (let k = 0; k < grandSons.length; k++) {
								if (grandSons[k][this.urlUUID] === path) {
									result = grandSons[k];
									break;
								}
							}
						}
					}
				}
			}
			return result;
		},
		/**
		 * @desc: 开发中
		 */
		onFuture() {
			this.$message({
				showClose: true,
				message: '功能开发中，敬请期待...'
			});
		}
	}
}
</script>

<style lang="less" scoped>
@itemWidth: 252px;
@itemHeight: 416px;
.archi-info{
  width: 100%;
  width: @itemWidth;
  height: @itemHeight;
  background: #fff;
	overflow: hidden;
	box-sizing: border-box;
	padding: 20px 16px;
	overflow: hidden;
  border-radius: 4px;
	.company-name {
		font-size: 14px;
		color: rgb(141, 141, 141);
		padding: 2px 0;
	}
	.archi-title {
		width: 100%;
		font-size: 18px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: left;
		color: #161616;
		line-height: 26px;
		padding-bottom: 4px;
		word-break: break-all;
	}
	.full-name {
		width: 100%;
		padding-bottom: 8px;
		font-size: 14px;
		font-family: PingFangSC, PingFangSC-Regular;
		font-weight: 400;
		text-align: justify;
		color: #8d8d8d;
		line-height: 22px;
		word-break: break-all;
		border-bottom: 1px solid #f2f4f8;
	}
	.action-item {
		display: flex;
		height: 32px;
		box-sizing: border-box;
		padding: 5px 0;
		margin-top: 12px;
		cursor: pointer;
		border-radius: 2px;
		&:hover {
			background: #d0e2ff;
		}
		&.screen {
			margin-top: 8px;
			.icon-pre {
				color: #9b94a7;
			}
		}
		.iconfont {
			line-height: 22px;
			font-size: 16px;
			&.icon-pre {
        padding-left: 5px;
				color: #33b1ff;
			}
			&.icon-screen{
        padding-left: 5px;
				color: #be95ff;
			}
			&.icon-go {
				color: #8d8d8d;
			}
		}
		.label {
			flex: 1;
			box-sizing: border-box;
			padding: 0 8px;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			text-align: left;
			color: #161616;
			line-height: 22px;
			// color:#8d8d8d;
		}
	}
	.entry-btn {
		display: block;
		width: 100%;
		height: 32px;
		margin-top: 50px;
		background: #0f62fe;
		border-radius: 2px;
		text-align: center;
		font-size: 14px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		color: #ffffff;
		line-height: 32px;
	}
}
</style>