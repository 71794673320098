<!--
 * @Author: zqy
 * @Date: 2023-06-30 10:57:43
 * @LastEditTime: 2023-06-30 15:10:57
 * @Description: 
 * @LastEditors: zqy
-->
<template>
  <div>
    <div class="app-qrcode-icon" @click="active=!active">
      <i class="iconfont iconyidong"></i>
      <div class="qrcode-box" :style="active ? 'height: 160px;' : 'height: 0;'">
        <p class="info">扫码下载APP</p>
        <img :src="qrCode" alt="" width="100" height="100">
        <p class="info">请使用系统自带扫码功能</p>
      </div>
    </div>
    <!-- mask -->
    <div class="actions-mask" v-show="active" @click.self="changeActive"></div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data(){
    return{
      qrCode: null,
      active: false
    }
  },
  methods:{
    changeActive() {
      console.log('*****************');
      this.active = !this.active;
      console.log('this.active:',this.active);
    }
  },  
  mounted(){
    // 生成二维码
    const params = {
      qrcode_data: `${process.env.VUE_APP_V3_HOST}h5/appDownload`,
      margin: 1,
      logo_path: ``
    }
    axios({
        params: params,
        url: `${process.env.VUE_APP_BASE_URL}api/v4/qrcode/info/url`,
        method: 'get',
        responseType: 'blob'
      }).then(res=>{
      console.log('res:',res);
      let blob = res.data;
      let reader = new FileReader();
      reader.readAsDataURL(blob);  // 转换为base64
      reader.onload = () => {
        this.qrCode= reader.result
      }
    })
  }
}
</script>
<style lang="less" scoped>
.app-qrcode-icon{
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  .qrcode-box{
    display: flex;
    top: 45px;
    left: -40px;
    position: absolute;
    z-index: 10;
    width: 160px;
    height: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    box-shadow: 0px 5px 10px -1px rgb(0 0 0 / 8%);
    transition: all 0.2s;
    overflow: hidden;
    background: #fff;
    .info{
      padding: 10px 0;
      font-size: 12px;
      color:#6D768F;
    }
  }
  &:hover{
    background: rgba(255, 255, 255, 0.1);
    .qrcode-box{
      height: 160px !important;
    }
  } 
  i{
    font-size: 26px;
    color: #fff;
  }
}
.actions-mask{
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: transparent;
  top: 0;
  left: 0;
  z-index: 2998;
  cursor: pointer;
}
</style>